const scrollStore = {
  namespaced: true,

  state: {
    scrollPos: 0,
    autoScroll: false,
    viewportHeight: 0,
    showBanner: true,
    disableBanner: false,
    lockPosition: false
  },

  mutations: {
    SET_SCROLL_POS: (state, val) => {
      state.scrollPos = val
    },

    SET_AUTOSCROLL: (state, val) => {
      state.autoScroll = val
    },

    SET_VIEWPORT_HEIGHT: (state, val) => {
      state.viewportHeight = val
    },

    SET_SHOW_BANNER: (state, val) => {
      if (!state.lockPosition) {
        state.showBanner = val
      }
    },
    SET_DISABLE_BANNER: (state, val) => {
      state.disableBanner = val
    },
    SET_SCROLL_LOCK: (state, val) => {
      state.lockPosition = val
    }
  },

  actions: {},

  getters: {}
}

export default scrollStore
