import router from "@/router"

let tooltipActiveTimeout
const tooltipActiveTimeout_seconds = 0.5

let tooltipInactiveTimeout
const tooltipInactiveTimeout_seconds = 0.5

export default {
  namespaced: true,

  state: {
    isNavInverted: false,
    isTooltipActive: false,
    modalQueueEnabled: false,
    routeTransitionState: true,
    navActiveElement: null,
    productPageOverlay: null
  },

  mutations: {
    SET_MODAL_QUEUE_ENABLED: state => {
      state.modalQueueEnabled = true
    },

    SET_TOOLTIP_ACTIVE: (state, _val) => {
      state.isTooltipActive = _val
    },

    SET_ROUTE_TRANSITION_STATE: (state, _val) => {
      state.routeTransitionState = _val
    },

    SET_NAV_ACTIVE_ELEMENT: (state, _val) => {
      state.navActiveElement = _val
    },

    SET_PRODUCT_PAGE_OVERLAY: (state, _val) => {
      state.productPageOverlay = _val
    },

    SET_NAV_INVERTED: (state, _val) => {
      state.isNavInverted = _val
    }
  },

  actions: {
    SHOW_LOGIN_OVERLAY: async ({ commit }, _payload = {}) => {
      if (
        _payload.redirectSource &&
        router.currentRoute.query.redirectSource != _payload.redirectSource
      ) {
        router
          .replace({
            params: { maintainScrollPos: true },
            query: {
              ...router.currentRoute.query,
              redirectSource: _payload.redirectSource
            }
          })
          .then(() => {
            commit("SET_NAV_ACTIVE_ELEMENT", "login")
          })
      } else {
        commit("SET_NAV_ACTIVE_ELEMENT", "login")
      }
    },

    TOGGLE_TOOLTIP: ({ commit }, _val) => {
      if (_val) {
        clearTimeout(tooltipInactiveTimeout)
        tooltipActiveTimeout = setTimeout(() => {
          commit("SET_TOOLTIP_ACTIVE", true)
        }, tooltipActiveTimeout_seconds * 1000)
      } else {
        clearTimeout(tooltipActiveTimeout)
        tooltipInactiveTimeout = setTimeout(() => {
          commit("SET_TOOLTIP_ACTIVE", false)
        }, tooltipInactiveTimeout_seconds * 1000)
      }
    }
  }
}
