// QUERYPARAMS
//////////////

// This module handles any query params that are included on any arrival URL that need to be passed on to Shopify
// It holds them in the store until the customer proceeds to checkout
// 'mapTo' can be used to map any that need to be set with a different key when passed on
// If 'mapTo' set to 'null', the original param name is maintained.

const acceptedQueryParams = [
  {
    // Duel passes a couple of params, eg duel_a, duel_b so we can match with regex
    regex: /^duel_*/,
    mapTo: null
  },
  {
    // 'inref' is the 'influencer referral' code, directly maps to Shopify's discount code
    regex: /inref/,
    mapTo: "discount"
  },
  {
    // ShareASale tracking param
    regex: /sscid/,
    mapTo: null
  }
]

export default {
  namespaced: true,

  state: {
    queryParamsMap: {} // { key: value, ... }
  },

  mutations: {
    SET_QUERY_PARAMS(state, queryParamsMap) {
      state.queryParamsMap = queryParamsMap
    }
  },

  actions: {
    CLEAR_PARAMS({ commit }) {
      commit("SET_QUERY_PARAMS", {})
    },
    PERSIST_PARAMS({ commit }) {
      const _tempParamsMap = {}
      for (const [key, value] of new URLSearchParams(
        window.location.search
      ).entries()) {
        let _paramIndex = acceptedQueryParams.findIndex(param =>
          key.match(param.regex)
        )
        if (_paramIndex > -1)
          _tempParamsMap[acceptedQueryParams[_paramIndex].mapTo || key] = value
      }

      commit("SET_QUERY_PARAMS", _tempParamsMap)
    }
  },

  getters: {
    getHasDiscountCode: state => {
      // TODO: This is rather literal but for now it's the only measure of whether there is a 'discount' param in the store.
      return !!state.queryParamsMap.discount
    }
  }
}
