import BleachAPI from "@/integration/BleachAPI"
import { localeToProperties } from "@/utils"
import router from "@/router"

export default {
  namespaced: true,

  state: {
    shopName: "",
    locale: "",
    currency: "", // ISO 4217 format,
    timeZone: "", // iana format,
    showLocaleSuggestionModal: false,
    suggestedLocale: "",
    suggestedShop: "",
    queueGeoFetch: false
  },

  mutations: {
    SET_CURRENCY: (state, currency) => {
      state.currency = currency
    },
    SET_LOCALE: (state, locale) => {
      state.locale = locale
    },
    SET_SHOPNAME: (state, shopName) => {
      state.shopName = shopName
    },
    SET_TIMEZONE: (state, timeZone) => {
      state.timeZone = timeZone
    },
    SET_FETCH_REQUEST: (state, payload) => {
      state.queueGeoFetch = payload
    },
    SET_LOCALE_SUGGESTION_STATUS: (state, status) => {
      state.showLocaleSuggestionModal = status
    },
    SET_LOCALE_SUGGESTED_VALUES: (state, { shopName, locale }) => {
      state.suggestedLocale = locale
      state.suggestedShop = shopName
    }
  },

  actions: {
    INIT_LOCALE({ commit, state, getters }) {
      // WE ALWAYS REQUEST WITH LOCALE FROM URL
      const { locale, shopName } = localeToProperties(getters.getLocaleFromPath)

      if (!state.locale) {
        // locale is not already held in localStorage
        commit("SET_FETCH_REQUEST", true)
      } else if (state.locale != locale) {
        // Existing locale preference is different from current locale
        commit("SET_LOCALE_SUGGESTED_VALUES", {
          shopName: state.shopName,
          locale: state.locale
        })
      }
      commit("SET_LOCALE", locale)
      commit("SET_SHOPNAME", shopName)
    },

    GET_GEODATA({ commit, state }) {
      if (state.queueGeoFetch) {
        return BleachAPI.getGeoLocation()
          .then(res => {
            const { locale, shopName, timeZone } = res.data.geoLocation
            commit("SET_TIMEZONE", timeZone.code)

            if (state.locale != locale) {
              commit("SET_LOCALE_SUGGESTION_STATUS", true)
              commit("SET_LOCALE_SUGGESTED_VALUES", {
                shopName,
                locale
              })
            }

            commit("SET_FETCH_REQUEST", false)
            commit("ui/SET_MODAL_QUEUE_ENABLED", null, { root: true })
          })
          .catch(err => console.log(err))
      } else if (state.suggestedLocale) {
        commit("SET_LOCALE_SUGGESTION_STATUS", true)
        commit("SET_FETCH_REQUEST", false)
        commit("ui/SET_MODAL_QUEUE_ENABLED", null, { root: true })
      } else {
        commit("ui/SET_MODAL_QUEUE_ENABLED", null, { root: true })
      }
    },
    CHANGE_LOCALE({ commit }, payload) {
      const { locale, shopName } = payload

      Promise.all([
        commit("SET_LOCALE", locale),
        commit("SET_SHOPNAME", shopName),
        commit("cart/RESET_CART", null, { root: true }),
        commit("cart/SET_CART_ID", null, { root: true })
      ]).then(() => {
        window.location.href =
          window.location.origin +
          localeToProperties(locale).pathPrefix +
          router.currentRoute.fullPath
      })
    },
    REFUSE_CHANGE({ commit }) {
      commit("SET_LOCALE_SUGGESTION_STATUS", false)
      commit("SET_LOCALE_SUGGESTED_VALUES", {
        shopName: "",
        locale: ""
      })
    }
  },

  getters: {
    formatPrice: ({ currency, locale }) => (price, quantity = 1) => {
      let cents = price

      if (typeof price === "object" && price !== null) {
        cents = price.cents
      }

      return new Intl.NumberFormat(locale, {
        minimumFractionDigits: cents % 100 == 0 ? 0 : 2,
        maximumFractionDigits: cents % 100 == 0 ? 0 : 2,
        style: "currency",
        currency,
        currencyDisplay: "symbol"
      }).format((cents * quantity) / 100)
    },

    getLocaleFromPath() {
      // human: get the first word after the top level domain slash
      return window.location.pathname.replace(/^\/([^\/]+).*/i, "$1")
    }
  }
}
